import axiosFrame from '@/utils/axiosFrame'

export default {
  getAdminMe: axiosFrame('get', '/manage/admin/me/', null),
  getDetail: axiosFrame('get', '/manage/update-content-re/detail/', null),
  getTimelineList: axiosFrame('get', '/manage/history/timeline-list/', null),
  createMemo: axiosFrame('post', '/manage/memo/create-memo/', null),
  createContent: axiosFrame('post', '/manage/update-content-re/create-content/', null),
  updateContent: axiosFrame('post', '/manage/update-content-re/update-content/', null),
}
