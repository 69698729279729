<template>
  <BModal
    :visible="isVisible"
    cancel-variant="outline-secondary"
    centered
    :modal-class="isDanger ?'modal-danger' : undefined"
    no-close-on-backdrop
    :title="title"
    :busy="isBusy"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <slot>
      {{ content }}
    </slot>
    <template v-slot:modal-footer="{ cancel }">
      <BButton
        v-if="!okOnly"
        variant="outline-secondary"
        :disabled="isBusy"
        @click="cancel"
      >
        {{ cancelTitle }}
      </BButton>
      <BButton
        :variant="isDanger ? 'danger': 'primary'"
        :disabled="isBusy"
        @click="$emit('ok')"
      >
        <BSpinner
          v-if="isBusy"
          small
        />
        {{ okTitle }}
      </BButton>
    </template>
  </BModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BModal,
  BButton,
  BSpinner,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BModal,
    BButton,
    BSpinner,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    okTitle: {
      type: String,
      default: "확인",
    },
    cancelTitle: {
      type: String,
      default: "취소",
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "ok",
    "hidden",
  ],
  setup() {

  },
})
</script>
