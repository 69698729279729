<template>
  <div>
    <BCard :title="title">
      <AppTimeline>
        <AppTimelineItem
          v-for="timeline in timelineList"
          :key="timeline.idx"
          variant="success"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6
              class="tg-clickable"
              @click="turnOnModal(timeline.record_meta)"
              v-text="resolveTitle(timeline)"
            />
            <small
              class="timeline-item-time text-nowrap text-muted"
              v-text="resolveDateTime(timeline.created_at)"
            />
          </div>
          <p
            class="mb-0"
            v-text="timeline.text"
          />
        </AppTimelineItem>
      </AppTimeline>
    </BCard>
    <TagbyModal
      :isVisible="isVisibleModal"
      title="데이터 기록"
      ok-only
      @ok="turnOffModal"
      @hidden="turnOffModal"
    >
      <Prism
        :code="recordMetaCode"
        style="max-height: 300px"
      />
    </TagbyModal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
} from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue"
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import Prism from "vue-prism-component"
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"
import useResolveValue from "@/utils/resolveValue"
import TagbyModal from "@/components/TagbyModal.vue"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    TagbyModal,
    Prism,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    timelineList: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveTitle = timeline => {
      if (timeline.admin == null) {
        return timeline.title
      }
      return `${timeline.title} (${timeline.admin.name})`
    }
    const { resolveDateTime } = useResolveValue()

    const isVisibleModal = ref(false)
    const recordMeta = ref()
    const recordMetaCode = computed(() => {
      if (!recordMeta.value) {
        return ""
      }
      return JSON.stringify(recordMeta.value, null, 4)
    })
    const turnOnModal = metaValue => {
      isVisibleModal.value = true
      recordMeta.value = metaValue
    }
    const turnOffModal = () => {
      isVisibleModal.value = false
    }

    return {
      isVisibleModal,
      recordMeta,
      recordMetaCode,
      resolveTitle,
      resolveDateTime,
      turnOnModal,
      turnOffModal,
    }
  },
})
</script>

<style scoped>
.tg-clickable:hover {
  cursor: pointer;
  text-decoration-line: underline;;
}
</style>
