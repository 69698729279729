import state from './state'

export default () => {
  const turnOnModal = uuid => {
    state.deleteModal.isVisible = true
    state.deleteModal.targetId = uuid
  }

  const turnOffModal = () => {
    state.deleteModal.isVisible = false
  }

  const deleteItem = () => {
    state.content = state.content.filter(p => p.uuid !== state.deleteModal.targetId)
  }

  return {
    turnOnModal,
    turnOffModal,
    deleteItem,
  }
}
