import {
  computed,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'

import api from './api'
import state from './state'
import getters from './getters'
import uploadFile from '@/utils/uploadFile'
import useToast from '@/utils/toast'
import useFetch from './useFetch'
import useResolveValue from '@/utils/resolveValue'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveUtcStartDateTimeTz,
    resolveUtcEndDateTimeTz,
  } = useResolveValue()

  const {
    getDetail,
    getTimelineList,
  } = useFetch()

  const TEMPLATE = {
    title: null,
    content: null,
    img_url: null,
    state: 'ACTIVE',
  }

  const getTemplate = () => ({
    ...TEMPLATE,
    is_saved: false,
    uuid: uuidv4(),
  })

  const pick = b => Object.keys(TEMPLATE).reduce((p, c) => ({
    ...p,
    [c]: b[c],
  }), {})

  const item = computed(() => state.content[state.currentOrder])

  const inputImgFile = file => {
    if (!file) {
      return
    }
    state.isUploading = true
    uploadFile(file, 'update_content_info', '').then(response => {
      item.value.img_url = response.file_url
    }).catch(() => {
      makeToast('danger', '파일 업로드에 실패했습니다')
    }).finally(() => {
      state.isUploading = false
    })
  }

  const hasNewItem = computed(() => state.content.find(b => !b.is_saved))

  const createNewItem = () => {
    state.content = [
      ...state.content.slice(0, state.currentOrder),
      getTemplate(),
      ...state.content.slice(state.currentOrder),
    ]
  }

  const isFirstOrder = computed(() => state.currentOrder === 0)

  const isLastOrder = computed(() => {
    const limit = state.content.length - 1
    return state.currentOrder === limit
  })

  const moveNextItem = () => {
    if (isLastOrder.value) {
      return
    }
    const currentItem = state.content[state.currentOrder]
    const nextItem = state.content[state.currentOrder + 1]
    const temp = state.content
    temp[state.currentOrder] = nextItem
    temp[state.currentOrder + 1] = currentItem
    state.content = []
    state.content = temp
  }

  const movePrevItem = () => {
    if (isFirstOrder.value) {
      return
    }
    const currentItem = state.content[state.currentOrder]
    const prevItem = state.content[state.currentOrder - 1]
    const temp = state.content
    temp[state.currentOrder] = prevItem
    temp[state.currentOrder - 1] = currentItem
    state.content = []
    state.content = temp
  }

  const getContent = () => {
    getDetail().then(() => {
      if (!state.content?.length) {
        state.content = [getTemplate()]
      } else {
        state.content = state.content.map(b => ({
          ...b,
          is_saved: true,
          uuid: uuidv4(),
        }))
      }
    })
  }

  const createContent = () => {
    state.isSaving = true
    return api.createContent({
      version: state.version,
      link_url: state.link_url,
      state: state.state,
      content: state.content.map(b => pick(b)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      router.push({ name: 'update-content-re-list' })
    }).catch(() => {
      makeToast('danger', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  const updateContent = () => {
    state.isSaving = true
    return api.updateContent({
      content_idx: state.idx,
      version: state.version,
      link_url: state.link_url,
      state: state.state,
      content: state.content.map(b => pick(b)),
    }).then(() => {
      makeToast('primary', '저장에 성공했습니다')
      getContent()
      getTimelineList()
    }).catch(() => {
      makeToast('danger', '저장에 실패했습니다')
    }).finally(() => {
      state.isSaving = false
    })
  }

  return {
    item,
    inputImgFile,
    hasNewItem,
    createNewItem,
    moveNextItem,
    movePrevItem,
    isLastOrder,
    isFirstOrder,
    getContent,
    createContent,
    updateContent,
  }
}
