export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  version: null,
  link_url: null,
  state: 'ACTIVE',
  content: [],
  created_at: null,
  currentOrder: 0,
  isUploading: false,
  isSaving: false,
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  timelineList: [],
  deleteModal: {
    isVisible: false,
    targetId: null,
  },
})
