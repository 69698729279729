<template>
  <TagbyOverlay
    :show="state.isUploading"
  >
    <BCard>
      <BRow>
        <BCol
          cols="12"
          md="6"
        >
          <BButtonGroup class="ml-1">
            <BButton
              variant="outline-primary"
              :disabled="hasNewItem"
              @click="createNewItem"
            >
              새로 만들기
            </BButton>
            <BButton
              v-if="item != null"
              variant="outline-primary"
              @click="turnOnDeleteModal(item.uuid)"
            >
              삭제
            </BButton>
            <BButton
              v-if="item != null"
              variant="outline-primary"
              :disabled="isFirstOrder"
              @click="movePrevItem"
            >
              순서 앞으로
            </BButton>
            <BButton
              v-if="item != null"
              variant="outline-primary"
              :disabled="isLastOrder"
              @click="moveNextItem"
            >
              순서 뒤로
            </BButton>
          </BButtonGroup>

          <BCarousel
            v-if="item != null"
            v-model="state.currentOrder"
            :interval="null"
            controls
            indicators
          >
            <BCarouselSlide
              v-for="item in state.content"
              :key="item.idx"
              class="card-img-overlay"
              caption-tag="h2"
              :img-src="item.img_url"
            />
          </BCarousel>
          <div class="text-center">
            {{ state.currentOrder + 1 }} 번째
          </div>
        </BCol>
        <BCol
          v-if="item != null"
          cols="12"
          md="6"
        >
          <BFormGroup
            label="IMG"
          >
            <BFormFile @input="inputImgFile" />
          </BFormGroup>

          <BFormGroup
            label="TITLE"
          >
            <BFormTextarea v-model="item.title" />
          </BFormGroup>

          <BFormGroup
            label="CONTENT"
          >
            <BFormTextarea v-model="item.content" />
          </BFormGroup>

          <BFormGroup
            label="STATE"
          >
            <TagbySelect
              v-model="item.state"
              :options="stateOptions"
            />
          </BFormGroup>

        </BCol>
      </BRow>
    </BCard>
  </TagbyOverlay>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import state from '../../state'
import useContent from '../../useContent'
import useDeleteModal from '../../useDeleteModal'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyOverlay,
    BButtonGroup,
    BButton,
  },
  setup() {
    const {
      item,
      inputImgFile,
      hasNewItem,
      createNewItem,
      moveNextItem,
      movePrevItem,
      isLastOrder,
      isFirstOrder,
    } = useContent()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const redirectOptions = [
      { label: '홈', value: '/main/home' },
      { label: '캠페인 상세', value: '/campaign' },
      { label: '추천 캠페인', value: '/campaigns/recommend' },
      { label: '최근 등록 상품', value: '/products/recent' },
      { label: '링크', value: 'link' },
    ]

    const parseTextToHtml = text => text?.replaceAll('\n', '<br>') ?? ''
    const parseCarouselCaption = text => `<div class='text-white text-left'>${parseTextToHtml(text)}</div>`
    const parseCarouselText = text => `<div class='text-left'>${parseTextToHtml(text)}</div>`

    return {
      state,
      stateOptions,
      redirectOptions,
      parseCarouselCaption,
      parseCarouselText,
      item,
      inputImgFile,
      hasNewItem,
      createNewItem,
      moveNextItem,
      movePrevItem,
      isLastOrder,
      isFirstOrder,
      turnOnDeleteModal,
    }
  },
})
</script>
