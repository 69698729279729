import state from './state'

export default () => {
  const turnOnErrorReasonModal = err => {
    state.isVisibleErrorReasonModal = true
    if (err?.response?.data?.data != null) {
      const errorData = err?.response?.data?.data
      if (typeof errorData === 'string') {
        state.errorReason = errorData
      } else {
        state.errorReason = JSON.stringify(errorData, null, 4)
      }
    } else {
      state.errorReason = err.toString()
    }
  }
  const turnOffErrorReasonModal = () => {
    state.isVisibleErrorReasonModal = false
  }

  return {
    turnOnErrorReasonModal,
    turnOffErrorReasonModal,
  }
}
