<template>
  <TagbyDangerModal
    :visible="state.deleteModal.isVisible"
    title="삭제"
    content="해당 아이템을 삭제하시겠습니까?"
    @hidden="turnOffModal"
    @ok="deleteItem"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'
import useDeleteModal from '../../useDeleteModal'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      turnOffModal,
      deleteItem,
    } = useDeleteModal()

    return {
      state,
      turnOffModal,
      deleteItem,
    }
  },
})
</script>
