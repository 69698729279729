<template>
  <TagbyActionCard>
    <template #left>
      <CreateButton />
      <UpdateButton />
    </template>

    <template #right>
      <CreateMemoButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import CreateButton from './CreateButton.vue'
import UpdateButton from './UpdateButton.vue'
import CreateMemoButton from './CreateMemoButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    CreateButton,
    CreateMemoButton,
    UpdateButton,
  },
  setup() {

  },
})
</script>
