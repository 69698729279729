import getStateOrigin from './stateOrigin'
import state from './state'

export default () => {
  const resetState = () => {
    const stateOrigin = getStateOrigin()
    Object.keys(stateOrigin).forEach(name => {
      state[name] = stateOrigin[name]
    })
  }

  return {
    resetState,
  }
}
